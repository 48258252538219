import { render, staticRenderFns } from "./PlayGame.vue?vue&type=template&id=2857cb89&scoped=true&"
import script from "./PlayGame.vue?vue&type=script&lang=ts&"
export * from "./PlayGame.vue?vue&type=script&lang=ts&"
import style0 from "./PlayGame.vue?vue&type=style&index=0&id=2857cb89&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2857cb89",
  null
  
)

export default component.exports