
  import Vue from 'vue';
  import { MODAL_IDS } from "@/utils/modal";

  export default Vue.extend({
    name: 'pwa-result-individual-competition-modal',
    props: {
      score: {
        type: Number,
        required: true,
      },
      winScore: {
        type: Number,
        required: true,
      },
    },
    computed: {
      hasWon(): boolean {
        return this.score >= this.winScore;
      },
    },
    methods: {
      closeModal() {
        this.hideModalById(MODAL_IDS.RESULT_INDIVIDUAL_COMPETITION);
      },
    }
  });
