
  import Vue from 'vue';
  import PwaPlayGame from "@/components/competitions/PwaPlayGame.vue";
  import pageMixin from "@/mixins/page";
  import PwaResultIndividualCompetitionModal
    from "@/components/modals/competitions/PwaResultIndividualCompetitionModal.vue";
  import {MODAL_IDS} from "@/utils/modal";

  export default Vue.extend({
    name: "PlayGame",
    mixins: [pageMixin],
    components: {
      PwaPlayGame,
    },
    props: {
      gameAlias: {
        type: String,
        required: true,
      },
      competitionId: {
        type: Number,
        required: true,
      },
      resultId: {
        type: Number,
        required: true,
      },
      numAttempt: {
        type: Number,
        required: false,
        default: 1,
      },
      winScore: {
        type: Number,
        required: false,
        default: null,
      },
    },
    methods: {
      onEndMatch(score: number, winScore: number) {
        if (winScore && score) {
          const message = this.$createElement(PwaResultIndividualCompetitionModal, {
            props: {
              score,
              winScore,
            },
          });
          this.showModal({
            id: MODAL_IDS.RESULT_INDIVIDUAL_COMPETITION,
            message,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
            showOnRoot: true,
            contentClass: {'is-desktop': this.isDesktop, 'hide-footer': true, 'no-money': true},
          });
        }
      },
    }
  });
